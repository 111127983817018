@value colors: 'assets/css/colors.module.css';
@value secondTextColor from colors;

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  color:#182C3D !important;
}

.textFont {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: secondTextColor;
}

.button_alignment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin:2vh 0;
}
.stepperStyle{
  width: 40%;
  margin: auto;
}
.textStyle {
  font-family: 'DM Sans', sans-serif;
  color: secondTextColor;
  font-size: 19px;
}
.enrollAlignTexts{
  margin:14vh 0;
}
.enrollTextFirst{
  margin:4vh 0;
}
@media only screen and (max-width: 500px) {
  .stepperStyle{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .button_alignment{
    flex-direction: column;
  }
}