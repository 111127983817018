@value colors: 'assets/css/colors.module.css';
@value textColor from colors;

.enroll-button {
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
}
.enroll-button button {
  font-weight: 700;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
  border-color: #174E8C;
}
.enroll {
  font-family: 'DM Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
}