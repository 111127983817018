.crossStyle{
    text-align: end;
}
.modalStyle{
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    margin:auto 2rem;
    font-family: 'DM Sans', sans-serif;
}