@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Poppins';
}

/* @value brand: rgb(6, 127, 111); */

html,
body {
  min-height: 100vh;
  background-color: white;
}
a {
  color: #182C3D;
}
a:visited {
  color: #174E8C;
}
a:active {
  color: #174E8C;
}
.css_product-name__1NzqF {
  font-weight: 700;
  color: #174E8C;
}
.css_support-link__3qSM1 {
  margin-right: 15px;
  font-size: 18px;
  display: block;
}
.css_phone__2h-MH {
  color: black;
  text-decoration: none;
}

.buttons_enroll-button__15kXN {
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
}
.buttons_enroll-button__15kXN button {
  font-weight: 700;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
  border-color: #174E8C;
}
.buttons_enroll__1L_dH {
  font-family: 'DM Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
}
.enrollment_container__bfbBM {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  color:#182C3D !important;
}

.enrollment_textFont__2ZXy6 {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: undefined;
}

.enrollment_button_alignment__22J7S {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin:2vh 0;
}
.enrollment_stepperStyle__v-Nsb{
  width: 40%;
  margin: auto;
}
.enrollment_textStyle__3smYw {
  font-family: 'DM Sans', sans-serif;
  color: undefined;
  font-size: 19px;
}
.enrollment_enrollAlignTexts__3BRUM{
  margin:14vh 0;
}
.enrollment_enrollTextFirst__fQ2gu{
  margin:4vh 0;
}
@media only screen and (max-width: 500px) {
  .enrollment_stepperStyle__v-Nsb{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .enrollment_button_alignment__22J7S{
    flex-direction: column;
  }
}

.confirmation_brand-color__nuhu1 {
  color: #174E8C;
}
.confirmation_confirmation__s2or6 {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.confirmation_sub-headline__23v4l {
  font-weight: 700;
  margin-top: 20px;
}
.confirmation_completion-message__hHoJY {
  text-align: center;
  padding-right: 60px;
}
.confirmation_invite-dependent-button-section__1kUi0{
  text-align: center;
  margin-top: 15px;
}
.confirmation_success-message__ujcjC {
  font-size: 15px;
  display: block;
  margin: auto;
  max-width: 514px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
}
.confirmation_add-dependent-container__1dhvR {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.confirmation_another-dependent-container__sUL0y {
  display: inline-block;
  margin-left: 20px;
}
.confirmation_another-dependent-button__2S66L {
  /* margin-left: 20px; */
  
}
.confirmation_roundCircle__2ctnO{
  height:10px;
  min-width:10px;
  border-radius: 50%;
  background-color: #6387C5;
  margin-right: 10px;
}
.confirmation_code-and-steps__1YifD {
  padding-left: 60px;
  display: flex;
  flex-wrap: wrap;
}
.confirmation_textCircleDiv__u0tqP{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin:2rem auto;
  font-family: 'DM Sans', sans-serif;
}
.confirmation_your-code__M6GPe {
  margin-top: 125px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.confirmation_next-steps__2GraJ {
  margin-top: 100px;
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 36px;
}
.confirmation_step1__16wr1 {
  margin-top: 20px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #142A39
}
.confirmation_step2__181Wb {
  margin-top: 24px;
}


.confirmation_enrollment_header__3iaMF {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #142A39;
}
@media only screen and (max-width: 600px) {
  .confirmation_completion-message__hHoJY {
    padding: 0 20px;
  }
}
.hero_logo-container__1SVUz {
  height: 255px;
}
.hero_main-title__1N4Z6 {
  font-weight: 700;
  color: #182c3d;
}
.hero_sub-title__2iG0U {
  font-weight: 600;
  color: #182c3d;
  font-size: 20px;
}
.hero_blueText__2jGee {
  color: #182C3D;
}
.hero_elevateCopy__3PZ4D {
  margin-bottom: 36px;
  font-size: 18px;
}
.hero_elevateBottomSpacer__VI3yB,
.hero_elevateTopSpacer__1B1g6 {
  height: 100px;
}
.hero_kardiaCompleteBody__3kOQP {
  margin: 24px 0;
}
.hero_kardiaCompleteFeatureList__39m3K {
  line-height: 36px;
}
.hero_eligibilityTopSpacer__19xfx,
.hero_eligibilityBottomSpacer__26SIS {
  height: 90px;
}
.hero_eligibilitySubHead__1BXAV {
  margin-bottom: 24px;
  color: #182C3D;
}
.hero_eligibilityText__1J4FC {
  font-weight: 400 !important;
}
.hero_heartHealthHeadline__1LB9r {
  margin-top: 58px !important;
  color: #182C3D;
}
.hero_heartHealthSubHeadline__3FJlo {
  margin-bottom: 56px;
  font-size: 20px;
  color: #182C3D;
}
.hero_list__2ntx7 {
  -webkit-padding-start: 30px;
          padding-inline-start: 30px;
}
.hero_list__2ntx7 ::marker {
  color: #174E8C;
  font-size: 30px;
  line-height: 0;
}
.hero_heartHealthList__29iaS {
  margin-right: 60px;
}
.hero_heartHealthList__29iaS li {
  margin-bottom: 29px;
}
.hero_heartHealthList__29iaS li .hero_itemTitle__2E2Lq {
  margin-bottom: 8px;
}
.hero_outlinedButton2__1JNo5 {
  float: right;
  margin-right: 60px;
}
.hero_kardiaCompleteBanner__nx7wE {
  background-color: #174E8C;
  text-align: center;
  padding: 28px;
}
.hero_kardiaCompleteBanner__nx7wE h2 {
  color: white;
}
.hero_conditionBottomSpacer__1puaL {
  margin-bottom: 100px;
}

.register_titles__3UivG {
  text-align: center;
}
.register_subheadline__3E1ac {
 font-weight: 500;
 font-size: 19px;
 font-family: 'DM Sans', sans-serif;
}
.register_enrollLink__hFKLq{
  font-weight: 700;
  margin-bottom: 4vh;
  font-family: 'DM Sans', sans-serif;
  font-size: 19px;
}
.register_aLink__17-AR{
  color: #174E8C;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;
}
.register_titleEnrollText__wqIQo{
  margin: 4.5vh auto;
}
.register_cvsTitleEnrolltext__2LBiL{
  margin-bottom: 5vh;
  font-family: 'DM Sans', sans-serif;
}
.register_registration-form__1FtqI {
  display: block;
  margin: 0 50px;
}
.register_form-fields__16Iua {
  margin-top: 30px;
}
.register_stepperStyle__3len0{
  width: 40%;
  margin: auto;
}
.register_registration-headline__3OTXO {
  margin-bottom: 12px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  font-size: 26px;
}
.register_registration-error-display__dr1Nr {
  background: #F9E8E8;
  border: 2px solid rgb(204, 61, 63);
  border-radius: 8px;
  padding: 16px 16px 16px 68px;
  background-image: url(/static/media/error.3d96d4f2.svg);
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 35px 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  display: none;
}
.register_registration-error-display__dr1Nr .register_error-triangle__36y7l {
  width: 35px;
  height: 30px;
}
.register_registration-display-errors__2jAVB {
  display: block;
}
.register_registration-subtitle__1rFi6 {
  font-size: 17px;
  font-weight: 400;
  color: #142A39;
  font-family: 'DM Sans', sans-serif;
}
.register_all-fields-required__15bpc {
  color: rgb(204, 61, 63);
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  font-family: 'DM Sans', sans-serif;
}
.register_asterikStyle__3zOAv{
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.register_eligibility-intro__17pGF {
  font-size: 16px;
}
.register_eligibility-headline__OZ43- {
  margin-top: 22px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #142A39;
}
.register_errorLine__3MYNk{
  font-size: 13px;
  color: rgb(204, 61, 63);
  font-weight: 100;
  font-family: 'DM Sans', sans-serif;
}
.register_eligibility-label___MgN_ {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #142A39;
}
.register_form-row__3VACY {
  height: 100px;
}
.register_titleCheckBox__3CHcH{
  font-size: 15px;
  font-weight: bold;
  height: 40px;
}
.register_atrialHypertensionStyle__1fNVN{
  margin-left: 50px;
}
.register_form-consent__2rDYn {
  padding-top: 0px !important;
}
.register_add-dependent-container__2W41M {
  margin-bottom: 30px;
}
.register_submit-button-container__2nbDL {
  margin-top: 20px;
}

.register_backdrop__2DELi {
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 20px;
  top: -20px;
  bottom: -20px;
}
.register_backdrop-container__A-aES {
  position: relative;
}
.register_invite-outline-button__RX8pm {
  text-decoration:none;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #142A39;
  border-color: #142A39;
  font-family: 'DM Sans', sans-serif;
}
.register_atrialHypertensionStyle__1fNVN{
  margin-left: 70px;
}
.register_gridContainer__rjTtM{
  color:#182C3D !important;
  font-family: 'DM Sans', sans-serif;
}
@media only screen and (max-width: 900px) {
  .register_backdrop-container__A-aES {
    margin-top: 40px;
  }
  .register_eligibility__23H29 {
    margin: 0 50px;
  }
  .register_add-dependent-container__2W41M {
    margin-top: 30px;
  }
  .register_stepperStyle__3len0{
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .register_stepperStyle__3len0{
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .register_button_alignment__3fwhn{
    flex-direction: column;
  }
}
.controls_text-field__2sxJd {
  line-height: 34px;
  border: 1px solid rgba(20, 42, 57, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding-left: 6px;
  padding-right:  20px;
  outline-color: rgb(186, 191, 189);
  font-family: 'DM Sans', sans-serif;
}
.controls_invalid__q-Z0Q .controls_text-field__2sxJd {
  border-color: rgb(204, 61, 63);
  background-image: url(/static/media/error.3d96d4f2.svg);
  background-repeat: no-repeat;
  background-position: right 5px center;
  outline-color: rgb(204, 61, 63);
}
.controls_field-label__U6vwu {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  color: #142A39
}
.controls_asterikStyle__30c1d{
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.controls_field-label-checkbox__40unp {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #142A39;
  margin-left: 5px;
}
.controls_checkbox-field__H-7qh{
  width: 15px;
  height: 15px;
}
.controls_checkbox-grid__bwaOR {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
.controls_email-field-label__2UlFQ {
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.controls_remove__2mH01 {
  color: #174E8C;
  /* position: absolute; */
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* top: 1px;
  right: 0px; */
  text-transform: capitalize;
  padding-right: 0;
}
.controls_error__1xahO {
  color: rgb(204, 61, 63);
  display: none;
  font-size: 14px;
  text-transform: none;
  font-family: 'DM Sans', sans-serif;
}
.controls_invalid__q-Z0Q .controls_error__1xahO {
  display: block;
}
.controls_select-field__2pBUg {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 38px;
  font-family: 'DM Sans', sans-serif;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAASklEQVQ4jWNgGAVUB/eA+D8BfA+fAZ5A/BuP5j9A7E/IFSl4DMgm1iudWDR3EKsZBBiBeAmS5lVAzESKASDAAcTbgXgblD0KaAUAFSwi4DJ9f4oAAAAASUVORK5CYII=) no-repeat right #fff;
  background-position: right 10px top 10px;
  outline-color: rgb(186, 191, 189);
}
select::-ms-expand {
  display: none;
}
.controls_invalid__q-Z0Q .controls_select-field__2pBUg {
  background-position: right 10px center;
}

@media screen and ( max-width: 400px ) {
  .controls_email-field-label__2UlFQ  {
    display: inline;
  }
  .controls_remove__2mH01 {
    float: right;
  }
}
.errorModal_crossStyle__2aYJ1{
    text-align: end;
}
.errorModal_modalStyle__3cgbS{
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    margin:auto 2rem;
    font-family: 'DM Sans', sans-serif;
}
